import * as React from "react"
import Layout from "../../components/layout"
import '../../style.css'
import Star from "../../components/star";

const FGC = () => {
    const info = [
        {
            title:'ACTIVE GALACTIC NUCLEI',
            image:'/AGNhcentrl.jpg',
            credit:'Credit: Aurore Simonnet, Sonoma State University',
            description:'At the center of an active galaxy lies a monster: a supermassive black hole. As matter falls toward the hole, it forms a rotating, flattened disk called an accretion disk. Through processes still unknown, some black holes can form jets of matter and energy that stream outward. Active galactic nuclei, like the one depicted here in an artist’s illustration, are sites of gamma ray formation. Fermi will observe thousands of such sources.',
        },
        {
            title:'PULSAR',
            image:'/crabl.jpg',
            credit:'Credit: NASA/Chandra Observatory',
            description:'If a magnetic neutron star spins rapidly, it will create beams of energy that rotate along with the star like the beams from a lighthouse. When one sweeps over the Earth, we see a pulse of energy from it. Some pulsars emit gamma rays, but there are two competing theories as to the origin of the gamma-radiation. Fermi observations may distinguish between the two models, perhaps ending this long-standing mystery.',
        },
        {
            title:'SUPERNOVA REMNANT',
            image:'/snrg292018.png',
            credit:'Credit: NASA/Chandra Observatory',
            description:'The turbulent magnetic fields generated in the shock waves from a supernova may be the birthplace of cosmic rays, extremely energetic particles that speed across space. Fermi will peer with unprecedented clarity into the region where cosmic rays are formed, helping scientists solve the riddle of how these particles are accelerated to such fantastic energies.',
        },
        {
            title:'SOLAR FLARE',
            image:'/solarflare2l.jpg',
            credit:'Credit: NASA/Chandra Observatory',
            description:'Our own modest star is capable of extraordinary outbursts. The writhing interior of the Sun generates twisted, complex magnetic field lines. When these break and reconnect, they can generate vast amounts of energy, equal to a million megatons of TNT. Energetic subatomic particles are created in such events, which decay to form gamma rays. Fermi will be sensitive to this emission, and may help untangle the mystery in our own backyard.'
        },
        {
            title:'GAMMA-RAY BURST SKY MAP',
            image:'/batse2704colorl.jpg',
            credit:'Credit: NASA/CGRO/BATSE',
            description:'Every day, somewhere in the Universe, a black hole is born. Its birth is announced by the scream of a gamma ray burst, an event which may release as much energy in a millisecond as the Sun will in its entire life. GRBs can come from anywhere in the sky, as seen in this map made by the BATSE instrument onboard NASA’s Compton Gamma Ray Observatory.',
        },
        {
            title:'HYPERNOVA',
            image:'/hypernovabl.gif',
            credit:'Credit: Dave Armbrecht, Spectrum Astro',
            description:'When a massive star (perhaps 10-40 times the Sun’s mass) explodes, it is called a supernova. If the star is very massive, more than 40 times that of Sun, and spins rapidly, it may form a hypernova, a kind of super-supernova. Theoretical arguments show that the energy released in a hypernova could power the longer duration gamma-ray bursts, which often show afterglows in X-ray, visible, infrared, and radio light. Fermi will determine if they commonly create high-energy gamma rays.'
        },
        {
            title:'BINARY NEUTRON STAR',
            image:'/binaneutrstrl.jpg',
            credit:'Credit: Aurore Simonnet, Sonoma State University',
            description:"Neutron stars are the fantastically dense remnants of massive stars after they explode as a supernova. If two neutron stars orbit each other closely, the distance between them will shrink as they emit gravitational energy. After a lifetime of dancing, they may merge creating a short-duration gamma-ray burst. Fermi will detect hundreds of short and long duration gamma-ray bursts, the biggest bangs in today's Universe."
        },
        {
            title:'Fermi OBSERVATORY',
            image:'/glastexplolb.jpg',
            credit:'Credit: Spectrum Astro and Aurore Simonnet',
            description:'The Fermi Telescope is a space observatory being built in collaboration among NASA, the Office of Science in the U. S. Department of Energy, and institutions in France, Germany, Italy, Japan, and Sweden. It will detect gamma rays: high-energy particles of light generated only in the most fantastically powerful events in the Universe, and will also search for signatures of dark matter. Spectrum-Astro Inc. is designing and building Fermi’s spacecraft vehicle.'
        },
        {
            title:'Fermi Simulated Sky Map',
            image:'/glastallskyl.jpg',
            credit:'Credit: Seth Digel, Stanford University',
            description:'The Universe glows in gamma rays. This glow is the combination of diffuse emission from our own Milky Way Galaxy, distant galaxies, pulsars, and a myriad of other, mostly unidentified, sources. Fermi will resolve much of the extragalactic diffuse radiation into individual sources, revealing the nature of the glow for the first time.'
        }
    ]

    return (
        <Layout>
            <div>
                <h1>Fermi Cube Images</h1>
                <Star />
                <h2>Intro</h2>
                <p>The Fermi Telescope is a space observatory that will study some of the most energetic events in the Universe. Fermi will bring unprecedented clarity to a Universe invisible to our eyes yet, quite literally, all around us. The images and illustrations on this cube describe some of the exotic wonders that will be studied by Fermi, following its launch in late 2006.</p>
                
                <div className="FC-images-container">
                    {info.map( item => 
                        <div className='FC-images-single-container' key={item.title}>
                            <a href={item.image} target='_blank' rel="noreferrer">
                            <img style={{maxWidth:'100%', margin:'5%'}} src={item.image} alt=''/>
                            </a>
                            <p>{item.credit}</p>
                            <h2>{item.title}</h2>
                            <p style={{textAlign:'start'}}>{item.description}</p>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    )
}

export default FGC